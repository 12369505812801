import { getHtprFinalLink } from "./Connection";
import { HashContext } from "./contexts/HashContext";
import { MainRouter, TGPageRouter } from "./routers/Routers";
import { APP, PLAY_STORE, TELEGRAM, HTPR } from "./utils/constants";
import Loader from "./Components/Loader";

function App() {
  const subDomain = window.location.hostname.split(".")[0];

  switch (subDomain) {
    case TELEGRAM:
      return (
        <HashContext.Provider value={window.location.pathname.split("/")[1]}>
          <TGPageRouter />
        </HashContext.Provider>
      );
    case APP:
      //referral program
      let win = "";
      if (window.location.pathname.split("/")[1] === "refHu") {
        win = window.open(
          PLAY_STORE +
            "&referrer=utm_medium%3Dreferral%26ref_uid%3D" +
            window.location.pathname.split("/")[2],
          "_self"
        );
      } else {
        win = window.open("https://hutti.in", "_self");
      }

      if (win !== null) win.focus();
      break;

    case HTPR: {
      const code = window.location.pathname.substring(1);
      getHtprFinalLink(code, (result) => {
        window.open(result.URL, "_self");
      });
      return <Loader />;
    }
    default: {
      return <MainRouter />;
    }
  }
}

export default App;
