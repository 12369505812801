import smart from "../images/shopsmrt.png";
import save from "../images/saveefrt.png";
import experience from "../images/grtexp.png";
import banner from "../images/about_banner.png";
import scanner from "../images/huttiQR.jpeg";
import girl from "../images/girl.png";
import big_banner from "../images/about-us-banner.png";
import "../css/aboutUs.css";
import { Link } from "react-router-dom";
import Aos from "aos";
import "aos/dist/aos.css";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import { PLAY_STORE } from "../utils/constants";

function AboutUs() {
  useEffect(() => {
    Aos.init({
      duration: 1000,
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>About Us</title>
        <meta
          name="description"
          content="Hutti is a smart cashback app connecting you to top brands, latest deals & exclusive coupons. Shop on trending brands to earn cashback on Myntra, Ajio, Flipkart, MuscleBlaze & many more brands whenever you purchase"
        />
      </Helmet>
      <div className="about-us-wrapper">
        <section id="section-one">
          <div className="about-banner-heading">
            <h1>
              explore
              <br />
              shop
              <br />
              <span className="blue-text">
                save <span id="effort"> effortlessly!</span>
              </span>
            </h1>
          </div>
          <div className="image-container">
            <img src={banner} alt="" id="mobile_banner" />
            <img src={big_banner} alt="" id="dektop_banner" />
          </div>
        </section>
        <section id="section-two">
          <div className="section-two-container">
            <h4>Hutti - Your Ultimate Savings Destination</h4>
            <p className="second-sub-heading">
              Hutti is a smart cashback platform that connects you to top
              brands, exclusive deals and exciting coupons. It helps you
              discover trending products, earn cashback and enjoy discounts on
              every purchase. Whether you're looking for trendy fashion, fitness
              gear, beauty products or tech essentials, Hutti brings you
              exclusive deals & offers from your favourite brands. Its more than
              just a shopping platform; it's your go-to destination for
              exploring the latest trends and saving big while doing it. Hutti
              makes shopping easier, more rewarding and fun for everyone.
            </p>
          </div>
        </section>
        <section id="section-three">
          <div className="section-three-heading">
            <div className="section-three-heading">
              <h4>
                Transforming the way, you save and shop smarter with Hutti!
              </h4>
            </div>
          </div>
          <div className="box-1" data-aos="fade-up">
            <div className="box-image">
              <img
                loading="lazy"
                decoding="async"
                className="size-full wp-image-14832 aligncenter"
                src={smart}
                alt=""
              />
            </div>
            <div className="box-detail">
              <h4>Shop Smarter</h4>
              <p>
                Hutti helps you shop smarter by offering exclusive deals and
                offers. It empowers shopper by providing best cashbacks,
                personalized recommendations, exclusive offers and a seamless
                shopping experience.
              </p>
            </div>
          </div>
          <div className="box-2" data-aos="fade-up">
            <div className="box-image">
              <img
                loading="lazy"
                decoding="async"
                className="wp-image-15216 size-full aligncenter"
                src={save}
                alt=""
              />
            </div>
            <div className="box-detail">
              <h4>Save Effortlessly</h4>
              <p>
                Hutti helps you save on every purchase with exciting cashback
                offers and deals. It is designed to maximize your savings with
                exclusive deals, cashbacks and coupons on every purchase.
              </p>
            </div>
          </div>
          <div className="box-3" data-aos="fade-up">
            <div className="box-image">
              <img
                loading="lazy"
                decoding="async"
                className="wp-image-15217 size-full aligncenter"
                src={experience}
                alt=""
              />
            </div>
            <div className="box-detail">
              <h4>Great User Experience </h4>
              <p>
                Hutti ensures that your cashback is quickly and seamlessly
                transferred to your bank account. Our user-friendly interface
                makes it easy to track your earnings and transfers, so you can
                enjoy your rewards without any hassle.
              </p>
            </div>
          </div>
        </section>
        <div className="section-four">
          <div className="section-four-container">
            <h4>Transforming the Way, You Shop</h4>
            <p>
              Hutti eliminates the hassle of missing out on great deals by
              bringing the best cashback offers directly to your fingertips.
              With Hutti, you can easily discover exclusive deals and maximize
              savings with every purchase. Shopping through Hutti can unlock
              endless coupons, ensuring smarter spending and maximizing the
              value of every transaction. Enjoy hassle-free access to the best
              offers and transform your shopping into a rewarding experience.
            </p>
          </div>
        </div>
        <div id="section-five">
          <div className="section-five-left" data-aos="fade-up">
            <div>
              <p id="shop">Shop now!</p>
            </div>
            <div className="qr-button">
              <img src={scanner} alt="" />
              <p>
                Download <br /> Hutti
              </p>
            </div>
            <Link
              to={
                PLAY_STORE +
                "&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste_hutti%26utm_campaign%3Dabout_us"
              }
              className="qr-mobile"
            >
              <p>Download Hutti</p>
            </Link>
          </div>
          <div className="section-five-right">
            <img src={girl} alt="" />
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
