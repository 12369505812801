import { useContext, useState, useEffect } from "react";
import BlogsShimmer from "./BlogsShimmer";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { BlogsContext } from "../contexts/BlogsContext";
import forward from "../images/fast-forward.png";
import badge from "../images/googleplay_badge.png";
import { PLAY_STORE } from "../utils/constants";
import "../css/blogs.css";
import { Helmet } from "react-helmet-async";

function Blogs() {
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);
  const [isBadgeVisible, setIsBadgeVisible] = useState(true);

  const { blogs } = useContext(BlogsContext);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const totalHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;

      if (scrollPosition + viewportHeight >= totalHeight - 600) {
        setIsBadgeVisible(false);
      } else {
        setIsBadgeVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      <Helmet>
        <title>Blogs</title>
        <meta name="description" content="Get expert shopping insights and tips with Hutti Blog to make smart buying decisions. Discover top deals, expert guidance and smart shopping tricks to save more and shop better every time"/>

      </Helmet>
      {blogs.length === 0 ? (
        <BlogsShimmer />
      ) : (
        <>
          <div className="blogs-wrapper">
            <div className="blogs-container">
              <div className="sitemap-link">
                <Link to="/" className="home-link">
                  Hutti
                </Link>
                <img src={forward} alt="" />
                <p>Blogs</p>
              </div>
            </div>
            <div className="blogs-container">

            {Object.keys(blogs).reverse().map((key, index) => {

                return (
                  <Link
                    to={`/blogs/${blogs[key].URL}`}
                    className="first-box"
                    key={index}
                    onClick={goToTop}
                  >
                    <img
                      src={blogs[key].BANNER}
                      alt={blogs[key].ALT}
                      onLoad={() => setIsBannerLoaded(true)}
                    />
                    <Skeleton
                      height={300}
                      style={!isBannerLoaded ? {} : { display: "none" }}
                    />
                    <div className="description-box">
                      <div className="description-box-heading">
                        <p>{blogs[key].HEADING}</p>
                        <h3>{blogs[key].CATEGORY}</h3>
                      </div>
                      <div className="description-box-date">
                        <p>Published On: {blogs[key].CREATEDON}</p>
                      </div>
                    </div>
                  </Link>
                );
              })}
            </div>
          </div>
          <div
            className={`hutti-google-play  ${isBadgeVisible ? "visible" : ""}`}
          >
            <Link
              to={
                PLAY_STORE +
                "&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste_hutti%26utm_campaign%3Dblogs"
              }
              className="badge_box"
            >
              <img src={badge} alt="" id="navbar_badge" />
            </Link>
          </div>
        </>
      )}
    </>
  );
}

export default Blogs;
