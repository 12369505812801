import React, { useEffect, useState, useContext } from "react";
import DOMPurify from "dompurify";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link, useParams } from "react-router-dom";
import DetailsShimmer from "./DetailsShimmer";
import { useMediaQuery } from "react-responsive";
import { BlogsContext } from "../contexts/BlogsContext";
import forward from "../images/fast-forward.png";
import badge from "../images/googleplay_badge.png";
import { Helmet } from "react-helmet-async";
import { PLAY_STORE } from "../utils/constants";
import "../css/blogdetails.css";

function BlogDetails() {
  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });
  const { blogs } = useContext(BlogsContext);
  const [blogData, setBlogData] = useState(null);
  const [isBadgeVisible, setIsBadgeVisible] = useState(true);

  const { url } = useParams();
  
  const [isBannerLoaded, setIsBannerLoaded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition =
        window.scrollY || document.documentElement.scrollTop;
      const totalHeight = document.documentElement.scrollHeight;
      const viewportHeight = window.innerHeight;

      if (scrollPosition + viewportHeight >= totalHeight - 600) {
        setIsBadgeVisible(false);
      } else {
        setIsBadgeVisible(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // blogs.find((item) => generateSlug(item.URL) === url)

  useEffect(() => {
    const filteredBlog = Object.values(blogs).find(blog => blog.URL === url);
    setBlogData(filteredBlog || null); // Set blogData to null if no matching blog is found
  }, [url, blogs]);

  function goToTop() {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  }

  return (
    <>
      {blogData ? (
        <>
          <Helmet>
            <title>{blogData["HEADING"]}</title>
            <meta name="description" content={blogData["METADESCRIPTION"]} />
          </Helmet>
          <div className="blog-details-wrapper">
            <div className="blog-details-box">
              <div className="first-block">
                <div className="sitemap-link">
                  <Link to="/" className="home-link">
                    Hutti
                  </Link>
                  <img src={forward} alt="" />
                  <Link to="/blogs" className="home-link">
                    Blogs
                  </Link>
                  <img src={forward} alt="" />
                  <p>{blogData["HEADING"]}</p>
                </div>
              </div>
              <div className="first-block">
                <div>
                  <h1>{blogData["HEADING"]}</h1>
                </div>
                <div>
                  <div
                    id="blog-description"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(blogData["INTRO"]),
                    }}
                    style={isBannerLoaded ? {} : { display: "none" }}
                  />
                </div>
                <br />
                <div>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: blogData["CREATOR"],
                    }}
                  />

                  <p id="blogDate">Published On: {blogData["CREATEDON"]}</p>
                </div>

                <img
                  src={blogData["BANNER"]}
                  alt={blogData["ALT"]}
                  onLoad={() => setIsBannerLoaded(true)}
                />
                <Skeleton
                  height={300}
                  width={isMobile ? 310 : 800}
                  style={!isBannerLoaded ? {} : { display: "none" }}
                />
                {/* <br /> */}
                {blogData.DETAILS.map((subitem, subindex) => (
                  <div
                    key={subindex}
                    style={isBannerLoaded ? {} : { display: "none" }}
                  >
                    <div
                      id="blog-subheading"
                      dangerouslySetInnerHTML={{
                        __html:
                          subitem && subitem.SUBHEADING
                            ? DOMPurify.sanitize(subitem.SUBHEADING)
                            : "",
                      }}
                    />
                    <br />
                    <div className="details-description">
                      {subitem && subitem.DESCRIPTION
                        ? subitem.DESCRIPTION.split("\\n\\n").map(
                            (line, lineIndex) => (
                              <div key={lineIndex}>
                                <div
                                  id="blog-info"
                                  dangerouslySetInnerHTML={{
                                    __html: line,
                                  }}
                                />
                                <br />
                              </div>
                            )
                          )
                        : null}
                    </div>
                  </div>
                ))}
              </div>
            </div>

            <div className="blog-cards-wrapper">
              <div className="blog-cards-container">
                {Object.keys(blogs).reverse().map((key, index) => {
                  if (!blogs) {
                    return null;
                  }
                  return blogs[key].URL !== blogData.URL ? (
                    <Link
                      to={`/blogs/${blogs[key].URL}`}
                      className="blog-card"
                      key={index}
                      onClick={goToTop}
                    >
                      <img src={blogs[key].BANNER} alt={blogs[key].ALT} />
                      <p>{blogs[key].CATEGORY}</p>
                      <p>{blogs[key].HEADING}</p>
                    </Link>
                  ) : null;
                })}
              </div>
            </div>
          </div>
        </>
      ) : (
        <DetailsShimmer />
      )}
      <div className={`hutti-google-play  ${isBadgeVisible ? "visible" : ""}`}>
        <Link
          to={PLAY_STORE + "&referrer=utm_source%3Dgoogle%26utm_medium%3Dwebiste_hutti%26utm_campaign%3Dblogs"}
          className="badge_box"
        >
          <img src={badge} alt="" id="navbar_badge" />
        </Link>
      </div>
    </>
  );
}

export default BlogDetails;
