import { Routes, Route } from "react-router-dom";

import MainHome from "../Components/MainHome";
import Blogs from "../Components/Blogs";
import BlogDetails from "../Components/BlogDetails";
import AboutUs from "../Components/AboutUs";
import Privacy from "../Components/Privacy";
import TermsAndConditions from "../Components/TermsAndConditions";
import AntiSpamPolicy from "../Components/AntiSpamPolicy";
import Website from "../Components/Website";
import Login from "../Components/TGApp";
import { BlogsProvider } from "../contexts/BlogsContext";
import BlogHome from "../Components/BlogHome";

export const MainRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<MainHome />}>
        <Route index element={<Website />} />
        <Route path="/about-hutti-cashback" element={<AboutUs />} />
      </Route>

      <Route
        path="/blogs/*"
        element={
          <BlogsProvider>
            <BlogHome />
          </BlogsProvider>
        }
      >
        <Route index element={<Blogs />} />
        <Route path=":url" element={<BlogDetails />} />
      </Route>

      <Route path="/privacy" element={<Privacy />} />
      <Route path="/terms" element={<TermsAndConditions />} />
      <Route path="/anti-spam-policy" element={<AntiSpamPolicy />} />
    </Routes>
  );
};

export const TGPageRouter = () => {
  return (
    <Routes>
      <Route path="/*" element={<Login />} />
    </Routes>
  );
};
